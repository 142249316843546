import React from "react";

const Map = () => {
  return (
    <div className="map">
      <img src={require("../assets/images/gps-map.jpg")} alt="location" />
    </div>
  );
};

export default Map;
