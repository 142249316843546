import React from "react";

const Juan = () => {
  return (
    <div className="juan">
      <img
        src={require("../assets/images/juan-gallegos.jpg")}
        alt="Juan Gallegos"
        title="Juan Gallegos"
      />
    </div>
  );
};

export default Juan;
