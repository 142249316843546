import React from "react";

const BusinessHours = () => {
  return (
    <div className="business-hours">
      <img src={require("../assets/images/business-hours.png")} alt="hours" />
    </div>
  );
};

export default BusinessHours;
